<template>
  <v-card style="height: 100%; overflow: hidden; border-left: 3px solid rgb(76, 175, 80);">
    <MainModal
        :main="{ component: 'FlatCourtCase', title: 'Судові справи' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_court_case)"
        :modal="show_court_case_dialog"
        :max-width="960"
        @updateItemModal="courtCaseUpdateItemModal"
    />
    <div class="work-place-table-items">
      <div class="work-place-table-items-header">
        <div class="work-place-table-items-row">
          <div class="work-place-table-items-col"></div>
          <div class="work-place-table-items-col">
            Тип документа
          </div>
          <div class="work-place-table-items-col">
            Дата
          </div>
          <div class="work-place-table-items-col" style="display: flex; align-items: center;">
            <div style="flex: 0 0 80px">Статус</div>
            <v-spacer></v-spacer>
            <v-tooltip bottom color="success">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="openCourtCaseCreateDialog"
                       v-bind="attrs"
                       v-on="on"
                       class="grey lighten-4 mr-1">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Створити нову претензію або судову справу</span>
            </v-tooltip>
          </div>
        </div>
      </div>
      <div class="work-place-table-items-body">
        <div v-for="item in items" :key="item.row_id"
             @click="onCourtCaseItemClick(item)"
            class="work-place-table-items-row">
          <div class="work-place-table-items-col">
            <v-icon
                :color="item.document_type === 'court_case' ? 'error' : 'secondary'"
                :size="17" class="ml-2"
            >
              <template v-if="item.document_type === 'court_case'">
                mdi-gavel
              </template>
              <template v-else>
                mdi-email-fast-outline
              </template>
            </v-icon>
          </div>
          <div class="work-place-table-items-col">
            <template v-if="item.document_type === 'pretense'">
              Претензія
            </template>
            <template v-if="item.document_type === 'court_case'">
              Судова справа
            </template>
          </div>
          <div class="work-place-table-items-col">
            {{ item.row_date | formatDate('DD.MM.YYYY') }}
          </div>
          <div class="work-place-table-items-col">
            <template v-if="item.document_type === 'pretense'">
              {{ getPretenseStatus(item.status) }}
            </template>
            <template v-else>
              {{ `${getCourtCaseDocument(item.document)} (${getCourtCaseStageOfEnforcement(item.status) })` }}
            </template>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";
import {ALERT_SHOW} from "@/store/actions/alert";
import {getCourtCaseStageOfEnforcement, getCourtCaseDocument, getPretenseStatus} from "@/utils/icons"
import courtCaseAPI from "@/utils/axios/flat_court_case";

export default {
  name: "CourtCaseWorkPlace",
  props: ['person_hash', 'refresh'],
  components: {
    MainModal: () => import("@/components/modal/MainModal")
  },
  computed: {
    ...mapGetters(
        {
          flat_current: 'getFlat',
        }
    )
  },
  data() {
    return {
      items: [],
      selected_court_case: {},
      show_court_case_dialog: false
    }
  },
  methods: {
    fetch(person_hash) {
      courtCaseAPI.get_all_for_work_place(person_hash)
          .then(response => response.data)
          .then(data => {
            this.items = data
            const counter = {}
            this.items.forEach(i => {
              if (!i.its_closed) {
                if (!Object.hasOwn(counter, i.id)) {
                  counter[i.id] = 1
                }
              }
            })
            this.$emit('afterFetch', {count: Object.values(counter).reduce((a, b) => a + b, 0)})
          })
          .catch(err => {
            this.$emit('afterFetch', {count: 0})
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
          });
    },
    getCourtCaseStageOfEnforcement,
    getCourtCaseDocument,
    getPretenseStatus,
    courtCaseUpdateItemModal(payload) {
      this.show_court_case_dialog = false
      this.selected_court_case = {}

      if (payload) {
        if (payload.payload === 'fetch') {
          const person_hash = this.$route.params.person_hash ? this.$route.params.person_hash : this.person_hash
          this.fetch(person_hash)
        }
      }
    },
    openCourtCaseCreateDialog() {
      this.selected_court_case = {
        flat_id: this.flat_current.id,
        document_type: 'pretense'
      }
      this.show_court_case_dialog = true
    },
    onCourtCaseItemClick(payload) {
      this.selected_court_case = JSON.parse(JSON.stringify(payload))
      this.show_court_case_dialog = true
    },
  },
  mounted() {
    const person_hash = this.$route.params.person_hash ? this.$route.params.person_hash : this.person_hash
    if (person_hash) {
      this.fetch(person_hash)
    }
  },
  watch: {
    person_hash: {
      immediate: true,
      handler(payload) {
        if (payload) {
          this.fetch(payload)
        } else {
          this.items = []
        }
      }
    },
    refresh: {
      immediate: false,
      handler(payload) {
        if (payload) {
          const person_hash = this.$route.params.person_hash ? this.$route.params.person_hash : this.person_hash
          this.fetch(person_hash)
        }
      }
    }
  }
}
</script>


<style scoped lang="scss">
.work-place-table-items-row {
  display: flex;
  align-items: center;
  min-height: 24px;

  .work-place-table-items-col {
    padding: 3px;

    &:nth-child(1) {
      flex: 0 0 46px;
    }
    &:nth-child(2) {
      flex: 0 0 130px;
    }
    &:nth-child(3) {
      flex: 0 0 90px;
    }
    &:nth-child(4) {
      flex: 1;
    }
  }
}

  .work-place-table-items {
    line-height: .92rem;
    height: 100%;

    .work-place-table-items-header {
      font-weight: 500;
      background-color: #fcfcfc;
      height: 46px;

      .work-place-table-items-row {
        border-bottom: thin solid rgba(0, 0, 0, 0.12);
        .work-place-table-items-col {
          padding: 5px 3px;
          font-size: .82rem;
        }
      }
    }

    .work-place-table-items-body {
      height: calc(100% - 40px);
      overflow: auto;

      .work-place-table-items-row {
        font-size: .88rem;
        border-bottom: 1px solid #f6f6f6;

        animation: ease-in-out all 100ms;

        &:hover {
          cursor: pointer;
          background-color: #f6f6f6;
        }
      }
    }

  }
</style>